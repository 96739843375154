import styled from "@emotion/styled"

export const Button = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
  border: ${props => (props.filled ? "none" : "solid")};
  background-color: ${props => (props.filled ? "black" : "transparent")};
  border-width: 2px;
  color: ${props => (props.filled ? "white" : "black")};
`
