import styled from "@emotion/styled"
import { mobile } from "../responsive"
import { useNavigate } from "react-router-dom"

const Container = styled.div`
  height: 350px;
  max-width 375px;
  ${mobile({ width: "90vw", margin: "10px 0", height: "90vw" })}
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin: 10px;
  cursor: pointer;

`

const Wrapper = styled.div`
  width: 400px;
  p {
    ${mobile({ fontSize: "20px" })};
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
  }
`

const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
`

const Image = styled.img`
  height: ${props => (props.zoom ? `${props.zoom * 100}` : "100")}%;
  transform: ${props => (props.translate ? props.translate : "none")};
  z-index: 2;
`

const Product = props => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`/neworder/${btoa(encodeURIComponent(JSON.stringify(props.product)))}`)
  }

  return (
    <Wrapper>
      <Container onClick={handleClick}>
        <Circle />
        <Image src={`../img/product/${props.product?.SKU}.png`} />
      </Container>
      <p>{`${props.product?.name} (${props.product?.SKU})`} </p>
    </Wrapper>
  )
}

export default Product
