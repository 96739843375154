import Home from "./pages/Home"
import React from "react"
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import { useDispatch } from "react-redux"
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth, GoogleAuthProvider, getRedirectResult } from "firebase/auth"
import { createContext } from "react"
import { loginFailure, loginSuccess } from "./redux/userRedux"
import Warehouse from "./pages/Warehouse"
import NewOrder from "./pages/NewOrder"
import ChooseProduct from "./pages/ChooseProduct"

const firebaseConfig = {
  apiKey: "AIzaSyCEGEuktbnnYoX4daiWH-Xty6wP2dJVUQ8",
  authDomain: "polypaper-warehouse-d2d20.firebaseapp.com",
  projectId: "polypaper-warehouse-d2d20",
  storageBucket: "polypaper-warehouse-d2d20.appspot.com",
  messagingSenderId: "714332373044",
  appId: "1:714332373044:web:de28baf351d09878324a61",
  measurementId: "G-HVESV1CDE3"
}

const firebase = initializeApp(firebaseConfig)
const db = getFirestore(firebase)
const googleAuthProvider = new GoogleAuthProvider()
const auth = getAuth()

const firebaseContextDefault = {
  db,
  googleAuthProvider,
  auth
}
export const FirebaseContext = createContext(firebaseContextDefault)

const App = () => {
  const dispatch = useDispatch()
  const auth = getAuth()
  getRedirectResult(auth)
    .then(result => {
      const credential = GoogleAuthProvider.credentialFromResult(result)
      const token = credential.accessToken
      const user = result.user
      dispatch(loginSuccess({ token, user }))
    })
    .catch(error => {
      const errorCode = error.code
      const errorMessage = error.message
      const email = error.customData?.email
      const credential = GoogleAuthProvider.credentialFromError(error)
      dispatch(loginFailure({ errorCode, errorMessage, email, credential }))
    })

  return (
    <FirebaseContext.Provider value={firebaseContextDefault}>
      <Router>
        <Routes>
          <Route path="/neworder/:product" element={<NewOrder />} />
          <Route path="/chooseproduct" element={<ChooseProduct />} />
          <Route path="/warehouse" element={<Warehouse />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </FirebaseContext.Provider>
  )
}

export default App
