import React from "react"
import styled from "@emotion/styled"
import { Button } from "../components/Button"
import { useSelector } from "react-redux"
import { mobile } from "../responsive"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { getDocs, collection } from "firebase/firestore"
import { FirebaseContext } from "../App"
import { useContext } from "react"

const translations = {
  white: "Bílé čtvrtky",
  brush: "Štětečky",
  box: "Krabičky",
  blacknose: "Černé čumáčky",
  envelope: "Obálky",
  pinknose: "Růžové čumáčky",
  glue: "Lepidýlka",
  grey: "Šedé čtvrtky",
  orange: "Oranžové čtvrtky",
  pink: "Růžové čtvrtky",
  scissors: "Nůžky",
  ruler: "Pravítka",
  black: "Černé čtvrtky"
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: top;
  p {
    ${mobile({ fontSize: "20px" })};
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
  }
`

export const Logo = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  padding-top: 10px;
  margin-bottom: 25px;
  ${mobile({ fontSize: "24px" })};
`

const WarehouseContainer = styled.div`
  padding-top: 25px;
`

const Home = () => {
  const { db } = useContext(FirebaseContext)
  const { user } = useSelector(state => state.user)
  const navigate = useNavigate()
  const [warehouse, setWarehouse] = useState(null)

  const email = user?.user?.email
  const loggedIn = email === "papermask.info@gmail.com"

  const fetchData = async () => {
    try {
      const result = await getDocs(collection(db, "warehouse"))
      const data = []
      {
        result.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })
      }
      setWarehouse(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!loggedIn)
    return (
      <MainContainer>
        <Logo>POLYPAPER ADMINISTRACE</Logo>
        <p>Nemáš právo používat administraci.</p>
      </MainContainer>
    )

  return (
    <MainContainer>
      <Logo>SKLAD</Logo>
      <Button onClick={() => navigate("/chooseproduct")}>VYTVOŘIT OBJEDNÁVKU</Button>
      <WarehouseContainer>
        {warehouse
          ?.filter(({ id }) => id === "mainwarehouse")
          ?.map(({ id, ...warehouseData }) =>
            Object.entries(warehouseData)
              .sort(([key1], [key2]) => translations[key1]?.localeCompare(translations[key2]))
              .map(([key, value]) => (
                <p key={key}>
                  {translations[key]}: {value}
                </p>
              ))
          )}
      </WarehouseContainer>
    </MainContainer>
  )
}

export default Home
