import styled from "@emotion/styled"
import React from "react"
import { Button } from "../components/Button"
import { mobile } from "../responsive"
import { useContext } from "react"
import { FirebaseContext } from "../App"
import { signInWithRedirect } from "firebase/auth"
import { useDispatch } from "react-redux"
import { loginStart } from "../redux/userRedux"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url("img/prague.jpg") top;
  background-size: cover;
`

export const Logo = styled.h1`
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  padding-top: 10px;
  ${mobile({ fontSize: "24px" })};
`

const IntroContainer = styled.div`
  padding-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;

  p {
    ${mobile({ fontSize: "20px" })};
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
  }
`

const Home = () => {
  const { auth, googleAuthProvider } = useContext(FirebaseContext)
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = () => {
    dispatch(loginStart())
    signInWithRedirect(auth, googleAuthProvider)
    navigate("/warehouse")
  }

  return (
    <MainContainer>
      <Logo>POLYPAPER ADMINISTRACE</Logo>
      <IntroContainer id="section2">
        <p>
          Správa skladu, vyřizování objednávek, přehled o zásobách a výdajích. Vše na jednom místě. <br />
        </p>
      </IntroContainer>
      <Button onClick={handleClick}>PŘIHLÁSIT</Button>
    </MainContainer>
  )
}

export default Home
